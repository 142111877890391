<template>
  <v-container fluid>
    <v-row no-gutters>
      <!-- Main Card -->
      <v-col cols="12" v-if="isLoaded" class="mt-6">
        <v-card class="mx-2">
          <v-row no-gutters>
            <v-col cols="12" class="mt-2">
              <v-row justify="space-between" no-gutters>
                <div class="mx-2">
                  <h1 v-if="product">
                    {{ product.name }}
                    <v-chip v-if="product.isTimeless">
                      {{ $t("products.labels.isTimeless") }}
                    </v-chip>
                  </h1>
                  <h1 v-else-if="component">
                    {{ component.name }}
                  </h1>
                </div>
                <div class="mx-2">
                  <!-- Edit study -->
                  <v-btn icon @click="setShowEditStudyDialog(true)">
                    <v-icon>fas fa-pencil</v-icon>
                  </v-btn>

                  <v-btn icon @click="setShowDeleteStudyDialog(true)">
                    <v-icon>far fa-trash</v-icon>
                  </v-btn>

                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>fas fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <!-- Edit product btn -->
                      <v-list-item
                        v-if="study.kind === 'PRODUCT'"
                        @click="setShowEditProductDialog(true)"
                      >
                        <v-list-item-title>
                          {{ $t("studies.buttons.editProduct") }}
                        </v-list-item-title>
                      </v-list-item>

                      <!-- Delete product btn -->
                      <v-list-item
                        v-if="study.kind === 'PRODUCT'"
                        @click="setShowDeleteProductDialog(true)"
                      >
                        <v-list-item-title>
                          {{ $t("studies.buttons.deleteProduct") }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-row no-gutters class="ma-2">
                <!-- Account -->
                <v-col cols="12">
                  <span>{{ $t("studies.labels.account") }} :</span>
                  <AccountLink :account="study.account" />
                </v-col>

                <!-- Type -->
                <v-col cols="12" v-if="study.kind === 'PRODUCT'">
                  <span>{{ $t("products.labels.type") }} :</span>
                  <v-chip label>{{ product.type }}</v-chip>
                </v-col>

                <!-- Type  -->
                <v-col cols="12" v-else-if="study.kind === 'COMPONENT'">
                  <span>{{ $t("components.labels.type") }} :</span>
                  <v-chip label>{{ component.type }}</v-chip>
                </v-col>

                <!-- Group -->
                <v-col cols="12" v-if="study.kind === 'PRODUCT'">
                  <span>{{ $t("products.labels.group") }} :</span>
                  <span>{{ product.group }}</span>
                </v-col>

                <!-- Public name -->
                <v-col cols="12" v-if="study.kind === 'PRODUCT'">
                  <span>{{ $t("products.labels.publicName") }} :</span>
                  <span>{{ product.publicName }}</span>
                </v-col>
              </v-row>
            </v-col>

            <!-- Tabs card -->
            <v-col cols="12" class="mt-6" v-if="isLoaded">
              <v-card class="mx-2">
                <v-tabs class="mx-2" v-model="tab" grow @change="switchTab">
                  <v-tab
                    class="text-transform-none font-size-14"
                    href="#tab-1"
                    :exact="true"
                  >
                    {{ $t("studies.tabs.specifications.title") }}
                  </v-tab>
                  <v-tab
                    v-if="study.kind === 'PRODUCT'"
                    class="text-transform-none font-size-14"
                    href="#tab-2"
                    :exact="true"
                  >
                    {{ $t("studies.tabs.components.title") }}
                  </v-tab>
                  <v-tab
                    class="text-transform-none font-size-14"
                    href="#tab-3"
                    :exact="true"
                  >
                    {{ $t("studies.tabs.acv.title") }}
                  </v-tab>
                  <v-tab
                    class="text-transform-none font-size-14"
                    disabled
                    href="#tab-4"
                    :exact="true"
                  >
                    {{ $t("studies.tabs.traceability.title") }}
                  </v-tab>
                  <v-tab
                    class="text-transform-none font-size-14"
                    href="#tab-5"
                    :exact="true"
                  >
                    {{ $t("studies.tabs.links.title") }}
                  </v-tab>
                  <v-tab
                    class="text-transform-none font-size-14"
                    href="#tab-6"
                    :exact="true"
                  >
                    {{ $t("studies.tabs.certifications.title") }}
                  </v-tab>
                  <v-tab
                    class="text-transform-none font-size-14"
                    href="#tab-7"
                    :exact="true"
                  >
                    {{ $t("studies.tabs.files.title") }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" class="ma-1">
                  <!-- Specifications -->
                  <v-tab-item value="tab-1">
                    <ProductSpecificationsTab
                      :product="product"
                      :component="component"
                      v-bind:study="study"
                      @refresh="getStudy"
                    />
                  </v-tab-item>

                  <!-- Components -->
                  <v-tab-item v-if="study.kind === 'PRODUCT'" value="tab-2">
                    <ComponentsTab
                      :product="product"
                      :component="component"
                      v-bind:study="study"
                    />
                  </v-tab-item>

                  <!-- ACV -->
                  <v-tab-item value="tab-3">
                    <ProductACVTab
                      :product="product"
                      :component="component"
                      v-bind:study="study"
                    />
                  </v-tab-item>

                  <!-- Traceability -->
                  <v-tab-item value="tab-4">
                    <ProductTraceabilityTab
                      :product="product"
                      :component="component"
                      v-bind:study="study"
                    />
                  </v-tab-item>

                  <!-- Links -->
                  <v-tab-item value="tab-5">
                    <ProductLinksTab
                      :component="component"
                      :product="product"
                      :study="study"
                    />
                  </v-tab-item>

                  <!-- Certifications -->
                  <v-tab-item value="tab-6">
                    <ProductCertificationsTab
                      :product="product"
                      :component="component"
                      :study="study"
                    />
                  </v-tab-item>

                  <!-- Files -->
                  <v-tab-item value="tab-7">
                    <ProductFilesTab
                      :product="product"
                      :component="component"
                      :study="study"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Product main dialog -->
    <ProductMainDialog
      v-if="showDialog.editProduct"
      :value="showDialog.editProduct"
      :item="product"
      @close="(needRefresh) => setShowEditProductDialog(false, needRefresh)"
    />

    <!-- Study Main Dialog -->
    <StudyDialog
      v-if="showDialog.editStudy"
      :value="showDialog.editStudy"
      :item="study"
      @close="(needRefresh) => setShowEditStudyDialog(false, needRefresh)"
    />

    <!-- Delete dialog for product -->
    <DeleteDialog
      v-if="showDialog.deleteProduct"
      :value="showDialog.deleteProduct"
      :title="$t('products.deleteDialog.title')"
      @submit="deleteProduct"
      @close="setShowDeleteProductDialog(false)"
    />

    <!-- Delete dialog for study -->
    <DeleteDialog
      v-if="showDialog.deleteStudy"
      :value="showDialog.deleteStudy"
      :title="$t('studies.deleteDialog.title')"
      @submit="deleteStudy"
      @close="setShowDeleteStudyDialog(false)"
    />
  </v-container>
</template>

<script>
import tabsUtils from "@/mixins/tabs-utils";
import formatter from "@/mixins/formatter";

export default {
  name: "StudyView",
  components: {
    StudyDialog: () => import("@/components/Studies/StudyDialog"),
    AccountLink: () => import("@/components/Common/Accounts/AccountLink"),
    ProductMainDialog: () => import("@/components/Studies/ProductMainDialog"),
    DeleteDialog: () => import("@/components/Common/DeleteDialog"),
    // Tabs
    ProductFilesTab: () => import("@/components/Studies/Files/ProductFilesTab"),
    ProductCertificationsTab: () =>
      import("@/components/Studies/Certifications/ProductCertificationsTab"),
    ProductTraceabilityTab: () =>
      import("@/components/Studies/Traceability/ProductTraceabilityTab"),
    ProductACVTab: () => import("@/components/Studies/ACV/ProductACVTab"),
    ProductLinksTab: () => import("@/components/Studies/Links/ProductLinksTab"),
    ProductSpecificationsTab: () =>
      import("@/components/Studies/Specifications/ProductSpecificationsTab"),
    ComponentsTab: () => import("@/components/Common/Components/ComponentsTab"),
  },

  mixins: [formatter, tabsUtils],

  props: {},

  data() {
    return {
      product: null,
      component: null,
      study: null,
      isLoaded: false,
      isLoadingDelete: false,
      showDialog: {
        editProduct: false,
        deleteProduct: false,
        editComponent: false,
        deleteComponent: false,
        editStudy: false,
        deleteStudy: false,
      },
    };
  },

  mounted() {
    this.getStudy();
  },

  methods: {
    getStudy() {
      this.isLoaded = false;
      this.$http
        .get(`/studies/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.study = res.data;
          this.product = res.data.product;
          this.component = res.data.component;
        })
        .catch((err) => {
          this.$store.commit("toast/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },

    deleteStudy() {
      this.isLoadingDelete = true;
      this.$http
        .delete(`/studies/${this.study.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("studies.deleteDialog.success")
          );
          this.setShowDeleteStudyDialog(false, true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingDelete = false;
        });
    },
    deleteProduct() {
      this.$http
        .delete(`/products/${this.product.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("products.deleteDialog.success")
          );
          this.setShowDeleteProductDialog(false, true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtnSave = false;
        });
    },

    setShowEditStudyDialog(val, needRefresh = false) {
      if (needRefresh) this.getStudy();
      this.showDialog.editStudy = val;
    },
    setShowDeleteStudyDialog(val, needRefresh = false) {
      if (needRefresh) this.$router.replace({ name: "Studies" });
      this.showDialog.deleteStudy = val;
    },

    setShowEditProductDialog(val, needRefresh = false) {
      if (needRefresh) this.getStudy();
      this.showDialog.editProduct = val;
    },
    setShowDeleteProductDialog(val, needRefresh = false) {
      if (needRefresh) this.$router.replace({ name: "Studies" });
      this.showDialog.deleteProduct = val;
    },
  },
};
</script>

<style scoped></style>
