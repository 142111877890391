<template>
  <v-container fluid>
    <v-row justify="start" class="ma-2" no-gutters>
      <v-col cols="12">
        <h1>{{ $t("studies.title") }}</h1>
      </v-col>

      <!-- Buttons -->
      <v-col cols="12" class="mt-6 text-right">
        <v-row no-gutters class="d-flex justify-end">
          <div class="mx-1">
            <v-btn color="primary" @click="setShowNewProductDialog(true)">
              {{ $t("studies.buttons.newProduct") }}
            </v-btn>
          </div>

          <div class="mx-1">
            <v-btn color="primary" @click="setShowAddDialog(true)">
              {{ $t("studies.buttons.add") }}
            </v-btn>
          </div>
        </v-row>
      </v-col>

      <v-col cols="12" class="mt-6">
        <div>
          <v-text-field
            v-model="search"
            append-icon="fas fa-search"
            :label="$t('common.inputs.search')"
            clearable
            v-on:click:clear="clearInput"
            @keyup.enter.native="searchTrigger"
            outlined
          ></v-text-field>
        </div>
      </v-col>

      <!-- Table -->
      <v-col cols="12" class="mt-2">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :loading="loading"
          :items-per-page="20"
          style="min-height: 600px"
          sort-by="createdAt"
          multi-sort
          :sort-desc="true"
          :footer-props="{ 'items-per-page-options': itemsPerPage }"
          class="elevation-1"
        >
          <!-- Name -->
          <template v-slot:item.name="{ item }">
            <router-link
              v-if="item.kind === 'PRODUCT'"
              :to="{ name: 'Study', params: { id: item.id } }"
            >
              {{ item.product.name }}
            </router-link>
            <router-link
              v-if="item.kind === 'COMPONENT'"
              :to="{ name: 'Study', params: { id: item.id } }"
            >
              {{ item.component.name }}
            </router-link>
          </template>

          <!-- Account -->
          <template v-slot:item.account="{ item }">
            <AccountLink :account="item.account" />
          </template>

          <!-- Type -->
          <template v-slot:item.type="{ item }">
            <v-chip>{{ item.type }}</v-chip>
          </template>

          <!-- Kind -->
          <template v-slot:item.kind="{ item }">
            <v-chip>{{ item.kind }}</v-chip>
          </template>

          <!-- Project Impact -->
          <template v-slot:item.projectImpact="{ item }">
            <ProjectImpactLink :project-impact="item.projectImpact" />
          </template>

          <!-- Brand Collection -->
          <template v-slot:item.brandCollection="{ item }">
            <BrandCollectionLink :brand-collection="item.brandCollection" />
          </template>

          <!-- State -->
          <template v-slot:item.state="{ item }">
            <v-chip label>{{ item.state }}</v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Main Study dialog -->
    <ProductMainDialog
      v-if="showDialog.newProduct"
      :value="showDialog.newProduct"
      :product="null"
      :item="null"
      @close="(needRefresh) => setShowNewProductDialog(false, needRefresh)"
    />

    <!-- Main Product dialog -->
    <StudyDialog
      v-if="showDialog.add"
      :value="showDialog.add"
      :item="null"
      @close="(needRefresh) => setShowAddDialog(false, needRefresh)"
    />
  </v-container>
</template>

<script>
import vuetifyDatatable from "@/mixins/vuetifyDataTable";
import formatter from "@/mixins/formatter";

export default {
  name: "ProductsView",

  components: {
    BrandCollectionLink: () =>
      import("@/components/Common/Cards/BrandCollectionLink"),
    ProjectImpactLink: () =>
      import("@/components/Common/Cards/ProjectImpactLink"),
    AccountLink: () => import("@/components/Common/Accounts/AccountLink"),
    StudyDialog: () => import("@/components/Studies/StudyDialog"),
    ProductMainDialog: () => import("@/components/Studies/ProductMainDialog"),
  },

  mixins: [vuetifyDatatable, formatter],

  data() {
    return {
      headers: [
        {
          text: this.$t("studies.datatable.headers.name"),
          value: "name",
          sortable: false,
          width: "40%",
        },
        {
          text: this.$t("studies.datatable.headers.account"),
          value: "account",
          sortable: false,
          width: "20%",
        },
        {
          text: this.$t("studies.datatable.headers.kind"),
          value: "kind",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("studies.datatable.headers.type"),
          value: "type",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("studies.datatable.headers.projectImpact"),
          value: "projectImpact",
          sortable: false,
          width: "20%",
        },
        {
          text: this.$t("studies.datatable.headers.brandCollection"),
          value: "brandCollection",
          sortable: false,
          width: "20%",
        },
        {
          text: this.$t("studies.datatable.headers.state"),
          value: "state",
          sortable: false,
          width: "10%",
        },
      ],
      URL_API: {
        GET_ALL: "/studies",
      },

      showDialog: {
        newProduct: false,
        add: false,
      },
      isLoadingExportBtn: false,
      savePaginationURL: true,
    };
  },

  created() {
    this.$store.commit("pageHead/setTitle", this.$t("studies.pageTitle"));
  },

  methods: {
    setShowAddDialog(val, reloadData = false) {
      if (reloadData) this.searchTrigger();
      this.showDialog.add = val;
    },

    setShowNewProductDialog(val, reloadData = false) {
      if (reloadData) this.searchTrigger();
      this.showDialog.newProduct = val;
    },

    goToOne(obj) {
      this.$router.push({
        name: "Product",
        params: { id: obj.id },
      });
    },
  },
};
</script>

<style scoped></style>
